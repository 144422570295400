import VisaCardImg from '../assets/payment/Visa.png';
import MasterCardCardImg from '../assets/payment/MasterCard.png';
import DinersClubCardImg from '../assets/payment/Diners Club.png';
import AmericanExpressCardImg from '../assets/payment/American Express.png';
import DiscoverCardImg from '../assets/payment/Discover.png';
import JCBCardImg from '../assets/payment/JCB.png';

const cardsImages = {
  VisaCardImg,
  MasterCardCardImg,
  DinersClubCardImg,
  AmericanExpressCardImg,
  DiscoverCardImg,
  JCBCardImg,
};

function getCardImage(brand) {
  return cardsImages[`${brand.trim()}CardImg`];
}

function getCardSummaryString(card) {
  return `${card.brand} ending ${card.last4} | Expires ${card.exp_month}/${String(card.exp_year).slice(2)}`;
}

function getACHSummaryString(payment) {
  const bankAccount = payment.us_bank_account;
  const routing = bankAccount.routing_number ? ` | routing number: ${bankAccount.routing_number}` : '';
  const status = bankAccount.status_details.length ? `| status: ${bankAccount.status_details.join(', ')}` : '';
  return `${bankAccount.bank_name} account ending ${bankAccount.last4}${routing}${status}`;
}

export { getCardImage, getCardSummaryString, getACHSummaryString };
