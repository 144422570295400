<template>
  <div id="app">
    <component
      :is="layout"
      v-if="layout"
    >
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import './styles/app.scss';

const defaultLayout = 'AppLayout';

export default {
  setup(props, context) {
    const layout = ref();
    const state = context.root.$store.state;
    watch(() => context.root.$route.name, changeLayout, { immediate: true });
    return {
      layout,
    };

    function changeLayout() {
      if (!context.root.$route.name) {
        return;
      }

      const layoutName = context.root.$route.meta.layout || defaultLayout;
      if (layout.value === layoutName) {
        return;
      }

      layout.value = () => import(`@/layouts/${layoutName}.vue`);
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
  position: relative;
}
</style>
