import Vue from 'vue';
import VueCompositionApiPlugin from '@vue/composition-api';
import Vue2TouchEvents from 'vue2-touch-events';
import elementLang from 'element-ui/lib/locale/lang/en';
import elementLocale from 'element-ui/lib/locale';
import Croppa from 'vue-croppa';
import * as VueGoogleMaps from 'vue2-google-maps';
import PortalVue from 'portal-vue';

import App from './App.vue';
import router from './router';
import store from './store/index';
import { registerComponents } from './components';
import { registerPlugins } from './plugins';
import { registerDirectives } from './directives';
import { initSentry } from './utils/SentryUtil';
import inactivityRefresh from './utils/InactivityRefreshUtil';

// element ui language
elementLocale.use(elementLang);

Vue.config.performance = process.env.VUE_APP_ENV === 'local';
Vue.config.productionTip = false;
Vue.use(VueCompositionApiPlugin);
Vue.use(Vue2TouchEvents);
Vue.use(Croppa);
Vue.use(VueGoogleMaps, { load: false });// it's already loaded for the places api, so don't load it again.
Vue.use(PortalVue);

registerComponents();
registerPlugins();
registerDirectives();

initSentry();

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const twoHoursInMiliseconds = 2 * 60 * 60 * 1000;
inactivityRefresh(twoHoursInMiliseconds);
