import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Plan from '@/constants/Plan';
import Role from '@/constants/Role';
import setTitleByRoute from './SetTitleByRoute';
import routes from './routes';

Vue.use(Router);

const SIGN_IN_PAGE = 'auth.sign-in';
const REGISTER_PAGE = 'auth.register';
const VERIFY_PAGE = 'auth.verify';
const PROPERTIES_PAGE = 'app.properties';
const LEASING_PAGE = 'app.lease';
const SUBSCRIBE_PAGE = 'app.subscribe';
const BUSINESS_ADDRESS_PAGE = 'app.business-address';
const LIMITED_PAGE = 'auth.limited-user';
const MARKETS_MAP = 'market.market-map';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  setTitleByRoute(to);

  if (to.name === MARKETS_MAP) {
    return next();
  }

  if (to.name === VERIFY_PAGE) {
    return next();
  }

  if (isAuthenticated() && from.name === SIGN_IN_PAGE && to.name === REGISTER_PAGE) {
    return next();
  }

  if (!settingsLoaded() && isAuthenticated()) {
    await store.dispatch('Auth/me');
  }

  if (isAuthenticated(to) && neverSubscribed() && ![SUBSCRIBE_PAGE, BUSINESS_ADDRESS_PAGE].includes(to.name)) {
    return next({ name: SUBSCRIBE_PAGE });
  }

  if (!isLimitedUserPage(to) && store.getters['Auth/plan'].name === Plan.BASIC && store.getters['Auth/user'].role === Role.MEMBER) {
    return next({ name: LIMITED_PAGE });
  }

  if (!isLimitedUserPage(to) && isAuthPage(to) && isAuthenticated() && settingsLoaded() && !isImpersonationPage(to)) {
    return next({ name: PROPERTIES_PAGE });
  }

  if (!isAuthPage(to) && !isAuthenticated()) {
    return next({ name: SIGN_IN_PAGE });
  }

  return next();
});

export default router;

function isAuthenticated() {
  return store.getters['Auth/isLoggedIn'];
}

function settingsLoaded() {
  return store.getters['Settings/settings'].length;
}

function isAuthPage(route) {
    if (!route.name) {
        return false;
    }
    return route.name.split('.')[0] === 'auth';
}

function isLimitedUserPage(route) {
  return route.name === LIMITED_PAGE;
}

function isImpersonationPage(route) {
  return route.name === 'auth.impersonation';
}

function neverSubscribed() {
  return !store.getters['Auth/lastSubscription'];
}

export function redirectToPropertiesPage(context) {
  context.root.$router.push({
    name: PROPERTIES_PAGE,
  }).catch(catchPushError);
}

export function redirectToSignIn(context) {
  context.root.$router.push({
    name: SIGN_IN_PAGE,
  }).catch(catchPushError);
}

export function catchPushError(err) {
  if (
    err.name !== 'NavigationDuplicated'
    && !err.message.includes('Avoided redundant navigation to current location')
    && process.env.VUE_APP_ENV === 'local'
  ) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
